import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'antd'
import { StyledPagination } from './styled'
const renderPaginationItem = (current, type, originalElement) => {
  if (type === 'prev') {
    return (
      <span className={'ant-pagination-item-link'}>
        <Icon type={'left'} />{' '}
        <FormattedMessage id={'pagination.button.prev'} />
      </span>
    )
  }
  if (type === 'next') {
    return (
      <span className={'ant-pagination-item-link'}>
        <FormattedMessage id={'pagination.button.next'} />{' '}
        <Icon type={'right'} />
      </span>
    )
  }
  return originalElement
}
const Pagination = props => {
  const { totalProducts, pageSize, currentPage, onChangeCallback } = props
  return (
    <StyledPagination
      total={totalProducts}
      current={currentPage}
      defaultPageSize={pageSize}
      pageSize={pageSize}
      itemRender={renderPaginationItem}
      onChange={onChangeCallback}
    />
  )
}
Pagination.propTypes = {
  currentPage: PropTypes.number,
  onChangeCallback: PropTypes.func,
  pageSize: PropTypes.number,
  totalProducts: PropTypes.number,
}
export default Pagination
