import { omit } from 'ramda'
import {
  GET_CATEGORY_BREADCRUMB,
  GET_CATEGORY_CHILDREN,
  GET_CATEGORY_PRODUCTS,
  GET_SEARCH_PRODUCTS,
  GET_SEARCH_SUGGEST,
  RESET_SEARCH_SUGGEST,
} from '../action-types'
const initialState = {
  children: [],
  breadcrumb: [],
  suggest: [],
}
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CATEGORY_CHILDREN:
      return { ...state, children: payload }
    case GET_CATEGORY_PRODUCTS:
    case GET_SEARCH_PRODUCTS:
      return {
        ...state,
        ...omit(['products'], payload),
        children: payload.products,
      }
    case GET_CATEGORY_BREADCRUMB:
      return { ...state, breadcrumb: payload }
    case GET_SEARCH_SUGGEST:
      return { ...state, ...payload }
    case RESET_SEARCH_SUGGEST:
      return { ...state, suggest: initialState.suggest }
    default:
      return state
  }
}
