import * as actionTypes from '../action-types'

export const setLoading = loading => {
  return { type: actionTypes.SET_LOADING, payload: loading }
}
export const initTotem = totem => {
  return { type: actionTypes.INIT_TOTEM, payload: totem }
}
export const initStore = storeId => async dispatch => {
  return await dispatch({ type: actionTypes.INIT_STORE, payload: storeId })
}

let timer = null
export const startTimer = () => dispatch => {
  clearInterval(timer)
  timer = setInterval(() => dispatch(updateTimer()), 1000)
  dispatch({ type: actionTypes.START_TIMER })
  dispatch(updateTimer())
}
const updateTimer = () => ({ type: actionTypes.UPDATE_TIMER })
export const stopTimer = () => {
  clearInterval(timer)
  return { type: actionTypes.STOP_TIMER }
}
