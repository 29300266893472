import styled from 'styled-components'
import { Pagination } from 'antd'
import { colors } from 'Common/constants'
export const StyledPagination = styled(Pagination)`
  text-align: right;
  & > .ant-pagination-item,
  & > .ant-pagination-prev span,
  & > .ant-pagination-next span {
    font-size: 14px;
    line-height: 32px;
    border-radius: 32px;
    border: none;
    box-shadow: 0 5px 5px -6px rgba(0, 0, 0, 0.5);
  }
  & > .ant-pagination-prev span,
  & > .ant-pagination-next span {
    padding: 0 16px;
  }
  & > .ant-pagination-item-active {
    font-weight: bold;
    line-height: 28px;
    border: 2px solid ${colors.main};
    &:hover > a,
    &:focus > a,
    & > a {
      color: ${colors.grey2};
    }
  }
`
