import { v4 as uuidv4 } from 'uuid'

import { SAVE_ANALYTICS, UPDATE_UUID } from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'
import { APPLICATION, BU } from 'Common/constants'

export const updateUuid = () => {
  return { type: UPDATE_UUID, payload: uuidv4() }
}
export const saveAnalytics = data => async (dispatch, getState) => {
  const { storeId, totem, uuid } = getState().App
  const { action, type, subType, subject, fullText } = data
  const body = {
    uuid,
    bu: BU,
    storeId,
    application: APPLICATION,
    subApplication: totem,
    action,
    type,
    subType,
    subject,
    fullText,
  }
  try {
    await api.analytics.save(body)
    return dispatch({ type: SAVE_ANALYTICS })
  } catch (error) {
    return handleServerError(error)
  }
}
