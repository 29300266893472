import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Layout } from 'antd'
import { connect } from 'react-redux'

import { startTimer, stopTimer } from 'Actions/app-actions'
import { updateUuid, saveAnalytics } from 'Actions/analytics-actions'
import { getSearchSuggest, resetSearchSuggest } from 'Actions/data-actions'

import Header from 'Components/Header'
import Footer from 'Components/Footer'
import { PaddedContent } from './styled'

import { TYPE_CATEGORY, TYPE_PRODUCT } from 'Common/constants'

class MainLayout extends Component {
  static propTypes = {
    beVersion: PropTypes.string,
    isHeaderVisible: PropTypes.bool,
    onSearchCallback: PropTypes.func,
    saveAnalytics: PropTypes.func,
    searchSuggest: PropTypes.array,
    storeId: PropTypes.string,
    updateUuid: PropTypes.func,
  }
  componentDidMount () {
    const { searchSuggest, resetSearchSuggest, startTimer } = this.props
    if (searchSuggest.length > 0) resetSearchSuggest()
    startTimer()

    // window.addEventListener('touchmove', event => {
    //   event.preventDefault()
    //   event.stopImmediatePropagation()
    // }, { passive: false })
  }
  componentDidUpdate () {
    const { timer, startTimer } = this.props
    // Reset timer after 1 minute
    timer === 60 && this.onStopTimer()
    // Restart timer
    timer === 0 && startTimer()
  }
  onStopTimer () {
    const { history, updateUuid, stopTimer } = this.props
    stopTimer()
    updateUuid()
    history.push(`/${history.location.search}`, { isModalVisible: false })
  }
  onSearchSelect = (text, itemId, itemDescription, type) => {
    const { history, saveAnalytics } = this.props
    if (itemId === 'searchProducts') {
      // Analytics event product search
      saveAnalytics({
        action: 'search',
        type: TYPE_PRODUCT,
        subType: 'opus',
        fullText: text,
      })
      // Navigate to products search
      history.push(`/search${history.location.search}`, { searchText: text })
    } else {
      // Navigate to selected item
      history.push(`/${itemId}${history.location.search}`, {
        itemId,
        isModalVisible: true,
      })
      // Analytics event category search
      saveAnalytics({
        action: 'search',
        type: TYPE_CATEGORY,
        subType: type,
        subject: itemId,
        fullText: text,
      })
    }
  }
  onSearch = searchText => {
    const { getSearchSuggest, resetSearchSuggest, startTimer } = this.props
    searchText.length >= 3 ? getSearchSuggest(searchText) : resetSearchSuggest()
    startTimer()
  }
  render () {
    const {
      children,
      beVersion,
      totem,
      storeId,
      isHeaderVisible,
      searchSuggest,
      searchProducts,
    } = this.props
    return (
      <Layout>
        {Header({
          isVisible: isHeaderVisible,
          searchSuggest,
          searchProducts,
          onSelectCallback: this.onSearchSelect,
          onSearchCallback: this.onSearch,
        })}
        <PaddedContent header={isHeaderVisible?.toString()}>
          {children}
        </PaddedContent>
        {Footer({ beVersion, totem, storeId })}
      </Layout>
    )
  }
}
const mapStateToProps = state => ({
  timer: state.App.timer,
  totem: state.App.totem,
  storeId: state.App.storeId,
  beVersion: state.Data.version,
  searchSuggest: state.Data.suggest,
  searchProducts: state.Data.searchProducts,
})
const mapDispatchToProps = {
  updateUuid,
  saveAnalytics,
  startTimer,
  stopTimer,
  getSearchSuggest,
  resetSearchSuggest,
}
export default connect(mapStateToProps, mapDispatchToProps)(MainLayout)
