import styled from 'styled-components'
import { Card, Divider } from 'antd'
import { colors } from 'Common/constants'
export const StyledPositionBox = styled.div`
  display: grid;
  justify-items: center;
  text-align: center;
`
export const PositionTitle = styled.p`
  font-size: 36px;
  font-weight: lighter;
`
export const PositionCard = styled(Card)`
  border: 2.5px solid ${colors.blue} !important;
  width: 100%;
  & > .ant-card-body {
    display: grid;
    justify-items: center;
    text-align: center;
  }
`
export const PositionDescription = styled.p`
  font-size: 18px;
  line-height: 1.2;
  margin: 16px 0 32px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const PositionDepartment = styled.div`
  width: 100%;
  font-size: 24px;
  margin: 0 0 24px;
  & > div {
    margin: 8px 0 0;
    display: grid;
    grid-gap: 8px;
    grid-template-columns: 1fr auto;
  }
  & > div > span {
    text-align: left;
    padding: 8px;
    display: inline-block;
    font-weight: bold;
    background-color: ${colors.blue};
    color: ${colors.white};
    &.rows {
      max-width: 160px;
      min-width: 160px;
    }
  }
`
export const PositionRow = styled.span`
  width: 40px;
  height: 24px;
  display: inline-block;
  margin-right: 8px;
  text-align: center;
  font-size: 18px;
  line-height: 24px;
  background-color: ${colors.white};
  color: ${colors.blue};
`
export const PositionDivider = styled(Divider)`
  margin: 16px 0 !important;
`
export const PositionShowroom = styled.div`
  font-size: 24px;
  width: 100%;
  & > span {
    margin: 8px auto 0;
    padding: 8px;
    display: block;
    width: 220px;
    line-height: 108px;
    font-weight: bold;
    background-color: ${colors.main};
    color: ${colors.white};
  }
`
