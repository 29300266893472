import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'

import { initStore, initTotem } from 'Actions/app-actions'
import { updateUuid } from 'Actions/analytics-actions'

import { getQueryParam } from 'Common/utils'

class Home extends Component {
  static propTypes = {
    storeId: PropTypes.string,
    updateUuid: PropTypes.func,
    uuid: PropTypes.string
  }

  async componentDidMount() {
    const {
      uuid,
      storeId,
      totem,
      updateUuid,
      initStore,
      initTotem,
    } = this.props
    const newStoreId = storeId || getQueryParam('storeId') || '35'
    const newTotem = totem || getQueryParam('totem') || '1'
    // Init store
    if (newStoreId) {
      await initStore(newStoreId)
    }
    // Init totem
    if (newTotem) initTotem(newTotem)

    // Init uuid for analytics
    if (!uuid) updateUuid()
  }
  
  render() {
    return (
      <Link to={{ pathname: '/0', state: { itemId: '0' } }}>
        <img src="/assets/img/start.png" alt="home" />
      </Link>
    )
  }
}

const mapStateToProps = state => {
  return {
    uuid: state.App.uuid,
    timer: state.App.timer,
    totem: state.App.totem,
    storeId: state.App.storeId,
  }
}
const mapDispatchToProps = {
  updateUuid,
  initStore,
  initTotem,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)
