import styled from 'styled-components'
import { Layout } from 'antd'
import { colors } from 'Common/constants'

export const StyledHeader = styled(Layout.Header)`
  background-color: ${colors.white};
  height: 90px;
  padding: 0;
`
export const HeaderInnerWrapper = styled.div`
  height: 90px;
  display: grid;
  grid-gap: 330px;
  grid-template-columns: auto 1fr auto;
  align-items: center;
`
export const AppLogo = styled.img`
  height: 56px;
`
