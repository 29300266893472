import Home from 'Containers/Home'
import Grid from 'Containers/Grid'
import Search from 'Containers/Search'

export const PATHS = {
  home: '/',
  search: '/search',
  grid: '/:nomencId',
}

export const CONFIG = [
  {
    key: 'search',
    path: PATHS.search,
    component: Search,
  },
  {
    key: 'grid',
    path: PATHS.grid,
    component: Grid,
  },
  // INSERT ROUTES HERE (Before root component)
  {
    key: PATHS.home,
    path: PATHS.home,
    component: Home,
  },
]
