import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import Loader from 'Components/Loader'
import PositionBox from 'Components/PositionBox'
import { StyledModal } from './styled'
const PositionModal = props => {
  const { totem, storeId, isPageLoading, isVisible, item, onCancelCallback } =
    props
  const positionImgUrl =
    'https://productslocalization-api.cloud.leroymerlin.it/img/v2/positions/show'
  return (
    <StyledModal
      centered
      width={1730}
      visible={isVisible}
      footer={null}
      onCancel={onCancelCallback}>
      {isPageLoading
        ? Loader({})
        : item && (
            <Row gutter={[30, 20]}>
              <Col span={32}>
                <img
                  src={`${positionImgUrl}?storeId=${storeId}&totem=${totem}&departmentId=${item.position.department?.id}`}
                  alt={'alt'}
                  width={'100%'}
                />
              </Col>
              <Col span={16}>{PositionBox({ item })}</Col>
            </Row>
          )}
    </StyledModal>
  )
}
PositionModal.propTypes = {
  totem: PropTypes.string,
  isVisible: PropTypes.bool,
  item: PropTypes.object,
  onCancelCallback: PropTypes.func,
}
export default PositionModal
