import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { Icon } from 'antd'
import { StyledMenu, HomeButton, BackButton } from './styled'
const Menu = props => {
  const history = useHistory()
  const location = useLocation()
  return (
    <StyledMenu>
      <HomeButton to={{ pathname: '/0', state: { itemId: '0' } }}>
        <Icon type={'home'} />
      </HomeButton>
      {location.state?.itemId !== '0' && (
        <BackButton onClick={() => history.goBack()}>
          <Icon type={'left'} />
          <span>
            <FormattedMessage id={'menu.button.back'} />
          </span>
        </BackButton>
      )}
    </StyledMenu>
  )
}
export default Menu
