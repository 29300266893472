import styled from 'styled-components'
import { Row } from 'antd'
import { colors } from './constants'
export const Title = styled.h1`
  font-size: 90px;
  font-weight: bold;
  color: ${colors.main};
  text-align: center;
  margin: 0;
`
export const SubTitle = styled.h3`
  font-size: 24px;
  font-weight: normal;
  color: ${colors.blue};
  text-align: center;
`
export const StyledRow = styled(Row)`
  margin: -10px 0 !important;
`
