import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import Loader from 'Components/Loader'
import Pin from 'Components/Pin'
import { HomeCard, CategoryCard, CategoryTitle, CategoryPrice } from './styled'

import { TYPE_CATEGORY, TYPE_PRODUCT } from 'Common/constants'

const CategoryCardDescription = item => {
  const { description, categoryType, price } = item
  return (
    <Fragment>
      <CategoryTitle>{description}</CategoryTitle>
      {!categoryType && <CategoryPrice>{price}</CategoryPrice>}
    </Fragment>
  )
}

const Card = props => {
  const [isLoading, setIsLoading] = useState(true)
  const history = useHistory()
  const { item, isHome, depth, openModalCallback } = props
  const { id, description, imgUrl, categoryType } = item
  const Card = isHome ? HomeCard : CategoryCard

  const itemType = categoryType ? TYPE_CATEGORY : TYPE_PRODUCT

  let cardActions = [Pin({})]
  if (!isHome)
    cardActions = [CategoryCardDescription(item), ...cardActions]

  return (
    <Card
      depth={depth}
      type={itemType}
      onClick={e =>
        !categoryType || e.target.classList[0] === 'pin'
          ? openModalCallback({ ...item, type: itemType })
          : history.push(`/${id}${history.location.search}`, { itemId: id })
      }
      bordered={false}
      cover={
        <Fragment>
          <img
            src={imgUrl}
            alt={description}
            onLoad={() => setIsLoading(false)}
          />
          {isLoading && Loader({ height: depth === 0 ? '60px' : '250px' })}
        </Fragment>
      }
      actions={cardActions}>
      {isHome && <Card.Meta title={description} />}
    </Card>
  )
}
Card.propTypes = {
  depth: PropTypes.number,
  isHome: PropTypes.bool,
  item: PropTypes.object,
  openModalCallback: PropTypes.func,
}
export default Card
