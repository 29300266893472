import * as actionTypes from '../action-types'

export const initialState = {
  isLoading: true,
  timer: 0,
}

const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actionTypes.SET_LOADING:
      return { ...state, isLoading: payload }
    case actionTypes.INIT_TOTEM:
      return { ...state, totem: payload }
    case actionTypes.INIT_STORE:
      return { ...state, storeId: payload }
    case actionTypes.START_TIMER:
    case actionTypes.STOP_TIMER:
      return { ...state, timer: initialState.timer }
    case actionTypes.UPDATE_TIMER:
      return { ...state, timer: state.timer + 1 }
    case actionTypes.UPDATE_UUID:
      return { ...state, uuid: payload }
    default:
      return state
  }
}

export default reducer
