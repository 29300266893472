import styled from 'styled-components'
import { Card } from 'antd'
import { colors, TYPE_PRODUCT } from 'Common/constants'
export const HomeCard = styled(Card)`
  text-align: center;
  & > .ant-card-cover {
    padding: 8px 72px;
  }
  & > .ant-card-body {
    padding: 0 8px;
    height: 72px;
    display: grid;
    align-items: center;
    & .ant-card-meta-title {
      font-size: 18px;
      font-weight: normal;
      color: ${colors.grey2};
      white-space: normal;
      line-height: 1.3;
    }
  }
  & > .ant-card-actions {
    border-top: 8px solid ${colors.grey1};
  }
`
export const CategoryCard = styled(Card)`
  & > .ant-card-cover {
    width: 290px;
    height: 290px;
  }
  & > .ant-card-body {
    padding: 0;
  }
  & > .ant-card-actions {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 0.8);
    & > li {
      color: ${colors.grey2};
      height: ${props => (props.type === TYPE_PRODUCT ? '70px' : '44px')};
      display: grid;
      align-items: center;
      border: none !important;
    }

    & > li:first-child {
      width: 70% !important;
      text-align: left;
      padding: 0 16px;
    }
    & > li:last-child {
      width: 30% !important;
    }
  }
`
export const CategoryTitle = styled.p`
  margin: 0;
  font-size: 16px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`
export const CategoryPrice = styled.p`
  font-size: 16px;
  font-weight: bold;
  margin: 4px 0 0;
`
