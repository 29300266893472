import {
  GET_CATEGORY_BREADCRUMB,
  GET_CATEGORY_CHILDREN,
  GET_CATEGORY_POSITION,
  GET_CATEGORY_PRODUCTS,
  GET_SEARCH_PRODUCTS,
  GET_SEARCH_SUGGEST,
  RESET_SEARCH_SUGGEST,
} from '../action-types'
import api from 'Common/api'
import { handleServerError } from 'Common/utils'

export const getCategoryChildren = categoryId => async (dispatch, getState) => {
  const storeId = getState().App.storeId
  try {
    const res = await api.category.children(storeId, categoryId)
    return dispatch({ type: GET_CATEGORY_CHILDREN, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getCategoryBreadcrumb = categoryId => async (
  dispatch,
  getState
) => {
  const storeId = getState().App.storeId
  try {
    const res = await api.category.breadcrumb(storeId, categoryId)
    return dispatch({ type: GET_CATEGORY_BREADCRUMB, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}
export const getCategoryPosition = categoryId => async (dispatch, getState) => {
  const storeId = getState().App.storeId
  try {
    const res = await api.category.position(storeId, categoryId)
    dispatch({ type: GET_CATEGORY_POSITION, payload: res.data })
    return res.data
  } catch (error) {
    return handleServerError(error)
  }
}
export const getCategoryProducts = (categoryId, currentPage) => async (
  dispatch,
  getState
) => {
  const storeId = getState().App.storeId
  try {
    const res = await api.category.products(storeId, categoryId, currentPage)
    return dispatch({
      type: GET_CATEGORY_PRODUCTS,
      payload: res.data,
    })
  } catch (error) {
    return handleServerError(error)
  }
}

export const getSearchSuggest = fullText => async (dispatch, getState) => {
  const storeId = getState().App.storeId
  try {
    const res = await api.search.suggest(storeId, fullText)
    return dispatch({ type: GET_SEARCH_SUGGEST, payload: res.data })
  } catch (error) {
    return handleServerError(error)
  }
}

export const resetSearchSuggest = () => {
  return { type: RESET_SEARCH_SUGGEST }
}
export const getSearchProducts = (fullText, currentPage) => async (
  dispatch,
  getState
) => {
  const storeId = getState().App.storeId
  try {
    const res = await api.search.products(storeId, fullText, currentPage)
    return dispatch({
      type: GET_SEARCH_PRODUCTS,
      payload: res.data,
    })
  } catch (error) {
    return handleServerError(error)
  }
}
