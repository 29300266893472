import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { injectIntl, FormattedMessage } from 'react-intl'
import { Input, Icon } from 'antd'

import Pin from 'Components/Pin'
import { StyledAutoComplete, SearchProductsSpan } from './styled'

const SearchBar = props => {
  const [searchText, updateSearchText] = useState('')
  const {
    intl,
    suggest,
    searchProducts,
    onSearchCallback,
    onSelectCallback,
  } = props
  const suggestOptions = suggest.map(item => (
    <StyledAutoComplete.Option
      key={item.id}
      label={item.description}
      type={item.type}>
      {Pin({ width: 20, style: { marginRight: 10 } })}
      <span>{item.description}</span>
    </StyledAutoComplete.Option>
  ))
  if (searchProducts && searchText) {
    suggestOptions.unshift(
      <StyledAutoComplete.Option key={'searchProducts'} label={searchText}>
        <SearchProductsSpan>
          <Icon type={'arrow-right'} />{' '}
          <FormattedMessage
            id={'searchBar.label.searchProducts'}
            values={{ searchText }}
          />
        </SearchProductsSpan>
      </StyledAutoComplete.Option>
    )
  }
  return (
    <StyledAutoComplete
      id={'search-bar'}
      dataSource={suggestOptions}
      onSearch={searchText => {
        updateSearchText(searchText)
        onSearchCallback(searchText)
      }}
      onSelect={(itemId, opt) => {
        onSelectCallback(searchText, itemId, opt.props.label, opt.props.type)
      }}
      placeholder={intl.formatMessage({ id: 'searchBar.placeholder' })}
      optionLabelProp={'label'}
      size={'large'}>
      <Input suffix={<Icon type={'search'} />} />
    </StyledAutoComplete>
  )
}
SearchBar.propTypes = {
  onSearchCallback: PropTypes.func,
  onSelectCallback: PropTypes.func,
  searchProducts: PropTypes.bool,
  suggest: PropTypes.array,
}
export default injectIntl(SearchBar)
