import React from 'react'
import { StyledLoader } from './styled'
const Loader = props => {
  const { height } = props
  return (
    <StyledLoader
      height={height}
      indicator={<img src={'/assets/img/loader.svg'} alt="loader" />}
    />
  )
}
export default Loader
