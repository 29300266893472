import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { colors } from 'Common/constants'
export const StyledMenu = styled.nav`
  height: inherit;
  display: grid;
  grid-template-columns: repeat(2, 90px);
`
export const HomeButton = styled(Link)`
  background-color: ${colors.main};
  color: ${colors.white};
  text-align: center;
  font-size: 60px;
  line-height: 80px;
  &:hover,
  &:active {
    color: ${colors.white};
  }
`
export const BackButton = styled.div`
  color: ${colors.main};
  display: grid;
  justify-content: center;
  align-items: center;
  & > .anticon {
    font-size: 40px;
  }
  & > span {
    text-transform: uppercase;
    line-height: 1;
  }
`
