import styled from 'styled-components'
import { Spin } from 'antd'
export const StyledLoader = styled(Spin)`
  display: grid !important;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: ${props => (props.height ? props.height : 'calc(100vh - 350px)')};
  & .ant-spin-dot {
    font-size: 100px;
  }
`
