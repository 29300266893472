import styled from 'styled-components'
import { Modal } from 'antd'
import { colors } from 'Common/constants'
export const StyledModal = styled(Modal)`
  color: ${colors.grey2};
  & .ant-modal-close {
    background-color: ${colors.blue};
    color: ${colors.white};
    border-radius: 50%;
    top: -30px;
    right: -30px;
    &:hover {
      color: ${colors.white};
    }
    & > .ant-modal-close-x {
      font-size: 30px;
    }
  }
`
