export const SET_LOADING = 'SET_LOADING'
export const INIT_TOTEM = 'INIT_TOTEM'
export const INIT_STORE = 'INIT_STORE'

export const START_TIMER = 'START_TIMER'
export const UPDATE_TIMER = 'UPDATE_TIMER'
export const STOP_TIMER = 'STOP_TIMER'

export const INIT_DATA = 'INIT_DATA'
export const UPDATE_DATA = 'UPDATE_DATA'
export const UPDATE_SUGGEST = 'UPDATE_SUGGEST'
export const RESET_SUGGEST = 'RESET_SUGGEST'

export const UPDATE_UUID = 'UPDATE_UUID'
export const SAVE_ANALYTICS = 'SAVE_ANALYTICS'

// API v2
export const GET_CATEGORY_CHILDREN = 'GET_CATEGORY_CHILDREN'
export const GET_CATEGORY_BREADCRUMB = 'GET_CATEGORY_BREADCRUMB'
export const GET_CATEGORY_POSITION = 'GET_CATEGORY_POSITION'
export const GET_CATEGORY_PRODUCTS = 'GET_CATEGORY_PRODUCTS'
export const GET_SEARCH_SUGGEST = 'GET_SEARCH_SUGGEST'
export const RESET_SEARCH_SUGGEST = 'RESET_SEARCH_SUGGEST'
export const GET_SEARCH_PRODUCTS = 'GET_SEARCH_PRODUCTS'
