const env = process.env.REACT_APP_ENV
const envConfig = {
  dev: {
    API_URL: 'http://localhost:8080',
  },
  test: {
    API_URL: 'https://test-finditems-api-dhounkje6q-ew.a.run.app',
  },
  prod: {
    API_URL: 'https://prod-finditems-api-w2e3et7fzq-ew.a.run.app',
  },
}

export default envConfig[env]
