import styled from 'styled-components'
import { AutoComplete } from 'antd'
import { colors } from 'Common/constants'
export const StyledAutoComplete = styled(AutoComplete)`
  width: 100%;
  max-width: 730px;
  display: block !important;
  margin: 0 auto !important;
  & input {
    border-color: ${colors.main};
  }
  & .anticon {
    color: ${colors.main};
  }
`
export const SearchProductsSpan = styled.span`
  font-weight: bold;
  color: ${colors.main};
  text-transform: uppercase;
`
