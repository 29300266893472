import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import { equals } from 'ramda'
import { Col } from 'antd'
import { connect } from 'react-redux'

import { startTimer } from 'Actions/app-actions'
import {
  getCategoryChildren,
  getCategoryBreadcrumb,
  getCategoryPosition,
  getCategoryProducts,
} from 'Actions/data-actions'
import { saveAnalytics } from 'Actions/analytics-actions'

import MainLayout from 'Hoc/MainLayout'
import Loader from 'Components/Loader'
import Breadcrumb from 'Components/Breadcrumb'
import Abstract from 'Components/Abstract'
import CardGrid from 'Components/CardGrid'
import CustomPagination from 'Components/Pagination'
import PositionModal from 'Components/PositionModal'
import { StyledRow } from 'Common/styled'

import { CATEGORY_TYPE_PRODUCT_BROWSING_NAV, CATEGORY_TYPE_PRODUCT_LIST_NAV, TYPE_CATEGORY, TYPE_PRODUCT } from 'Common/constants'

class Grid extends Component {
  static propTypes = {
    breadcrumb: PropTypes.array,
    getCategoryBreadcrumb: PropTypes.func,
    getCategoryChildren: PropTypes.func,
    getCategoryPosition: PropTypes.func,
    getCategoryProducts: PropTypes.func,
    items: PropTypes.array,
    limit: PropTypes.number,
    offset: PropTypes.number,
    saveAnalytics: PropTypes.func,
    storeId: PropTypes.string,
    total: PropTypes.number,
    updateData: PropTypes.func,
  }
  state = {
    isLoading: false,
    isModalVisible: false,
  }
  componentDidMount () {
    const { location } = this.props
    // Set location state
    if (location.state) this.setState(location.state)
    // Fetch data
    this.updateGridData()
  }
  componentDidUpdate (prevProps, prevState) {
    const { itemId } = this.state
    const { location, saveAnalytics, startTimer } = this.props
    // Set location state if different
    if (!equals(prevProps.location.state, location.state)) {
      // this.setState(location.state)
      this.updateGridData()
      startTimer()
    }
    // Analytics event browse category
    if (prevState.itemId !== itemId) {
      saveAnalytics({ action: 'browse', type: TYPE_CATEGORY, subject: itemId })
    }
  }
  updateGridData = async () => {
    const {
      location,
      history,
      saveAnalytics,
      getCategoryChildren,
      getCategoryProducts,
      getCategoryBreadcrumb,
      getCategoryPosition,
    } = this.props
    const itemId = location.state?.itemId
    if (itemId) {
      this.setState({ isLoading: true })

      let currentItem = {
        id: itemId,
        categoryType: CATEGORY_TYPE_PRODUCT_BROWSING_NAV,
        position: {}
      }
      if (itemId !== '0') currentItem = await getCategoryPosition(itemId)

      currentItem.categoryType === CATEGORY_TYPE_PRODUCT_LIST_NAV
        ? await getCategoryProducts(itemId)
        : await getCategoryChildren(itemId)
      await getCategoryBreadcrumb(itemId)

      if (location.state?.isModalVisible) {
        // Analytics event open modal from search
        saveAnalytics({
          action: 'open_modal',
          type: TYPE_CATEGORY,
          subType: 'search',
          subject: itemId,
        })
      }

      this.setState({
        ...location.state,
        isLoading: false,
        modalItem: currentItem,
      })
    } else {
      history.push(`/${history.location.search}`)
    }
  }
  onChangePagination = async currentPage => {
    const { itemId } = this.state
    const { getCategoryProducts } = this.props
    this.setState({ isLoading: true })
    await getCategoryProducts(itemId, currentPage)
    this.setState({ isLoading: false })
  }
  onOpenModal = item => {
    const { startTimer, saveAnalytics } = this.props
    this.setState({ isModalVisible: true, modalItem: item })
    startTimer()
    // Analytics event open modal
    saveAnalytics({
      action: 'open_modal',
      type: item.categoryType ? TYPE_CATEGORY : TYPE_PRODUCT,
      subType: 'browse',
      subject: item.id,
    })
  }
  onCloseModal = () => {
    const { startTimer } = this.props
    this.setState({ isModalVisible: false })
    startTimer()
  }
  render () {
    const { isLoading, isModalVisible, modalItem, itemId } = this.state
    const { history, totem, storeId, breadcrumb, items, total, limit, offset } =
      this.props
    const hasPagination = modalItem?.categoryType === CATEGORY_TYPE_PRODUCT_LIST_NAV || modalItem?.type === TYPE_PRODUCT

    return (
      <MainLayout isHeaderVisible={true} history={history}>
        <StyledRow gutter={[30, 20]}>
          <Col span={40} offset={4}>
            {Breadcrumb({ items: breadcrumb })}
            {Abstract()}
          </Col>
          {isLoading ? (
            Loader({})
          ) : (
            <Fragment>
              {CardGrid({
                items,
                isHome: itemId === '0',
                depth: breadcrumb.length,
                openModalCallback: this.onOpenModal,
              })}
              {hasPagination && (
                <Col span={40} offset={4}>
                  {CustomPagination({
                    totalProducts: total,
                    pageSize: limit,
                    currentPage: offset / limit + 1,
                    onChangeCallback: this.onChangePagination,
                  })}
                </Col>
              )}
            </Fragment>
          )}
        </StyledRow>
        <PositionModal
          totem={totem}
          storeId={storeId}
          item={modalItem}
          isVisible={isModalVisible}
          isPageLoading={isLoading}
          onCancelCallback={this.onCloseModal}
        />
      </MainLayout>
    )
  }
}
const mapStateToProps = state => {
  return {
    totem: state.App.totem,
    storeId: state.App.storeId,
    items: state.Data.children,
    breadcrumb: state.Data.breadcrumb,
    total: state.Data.total,
    limit: state.Data.limit,
    offset: state.Data.offset,
  }
}
const mapDispatchToProps = {
  saveAnalytics,
  startTimer,
  getCategoryChildren,
  getCategoryBreadcrumb,
  getCategoryPosition,
  getCategoryProducts,
}

export default connect(mapStateToProps, mapDispatchToProps)(Grid)
