import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd'

import Menu from 'Components/Menu'
import SearchBar from 'Components/SearchBar'
import { StyledHeader, HeaderInnerWrapper, AppLogo } from './styled'

const Header = props => {
  const {
    isVisible,
    searchSuggest,
    searchProducts,
    onSearchCallback,
    onSelectCallback,
  } = props
  return isVisible ? (
    <StyledHeader>
      <Row gutter={30}>
        <Col span={40} offset={4}>
          <HeaderInnerWrapper>
            <Menu />
            <SearchBar
              onSelectCallback={onSelectCallback}
              onSearchCallback={onSearchCallback}
              suggest={searchSuggest}
              searchProducts={searchProducts}
            />
            <Link to={'/'}>
              <AppLogo src={'/assets/img/LM-logo.png'} />
            </Link>
          </HeaderInnerWrapper>
        </Col>
      </Row>
    </StyledHeader>
  ) : null
}
Header.propTypes = {
  isVisible: PropTypes.bool,
  onSearchCallback: PropTypes.func,
  onSelectCallback: PropTypes.func,
  searchProducts: PropTypes.bool,
  searchSuggest: PropTypes.array,
}
export default Header
