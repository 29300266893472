import React from 'react'
import PropTypes from 'prop-types'
import { Col } from 'antd'
import Card from 'Components/Card'

const CardGrid = props => {
  const { items, isHome, depth, openModalCallback } = props
  return items.map((item, itemIndex) => {
    const colSpan = isHome ? 6 : 8
    const colOffset = isHome ? 0 : itemIndex % 5 ? 0 : 4

    return (
      <Col key={item.id} span={colSpan} offset={colOffset}>
        <Card
          item={item}
          isHome={isHome}
          depth={depth}
          openModalCallback={openModalCallback}
        />
      </Col>
    )
  })
}
CardGrid.propTypes = {
  depth: PropTypes.number,
  isHome: PropTypes.bool,
  items: PropTypes.array,
  openModalCallback: PropTypes.func,
}
export default CardGrid
