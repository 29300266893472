import React from 'react'
import { Switch, Route } from 'react-router-dom'

import { CONFIG } from './constants'

const renderRoute = ({ component, path, key, ...props }) => {
  const Comp = component
  return (
    <Route
      key={key}
      path={path}
      component={routeProps => <Comp {...routeProps} {...props} />}
    />
  )
}

const Routes = () => <Switch>{CONFIG.map(renderRoute)}</Switch>

export default Routes
