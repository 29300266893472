import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { StyledFooter } from './styled'

const Footer = props => {
  const { beVersion, totem, storeId } = props
  return (
    <StyledFooter>
      <small>
        <FormattedMessage
          id={'footer.label.feVersion'}
          values={{ feVersion: process.env.REACT_APP_VERSION }}
        />
      </small>
      {beVersion ? (
        <small>
          <FormattedMessage
            id={'footer.label.beVersion'}
            values={{ beVersion }}
          />
        </small>
      ) : null}

      {totem ? (
        <small>
          <FormattedMessage id={'footer.label.totem'} values={{ totem }} />
        </small>
      ) : null}

      {storeId ? (
        <small>
          <FormattedMessage id={'footer.label.store'} values={{ storeId }} />
        </small>
      ) : null}

      <small>{process.env.REACT_APP_ENV}</small>
    </StyledFooter>
  )
}
Footer.propTypes = {
  beVersion: PropTypes.string,
  storeId: PropTypes.string,
  totem: PropTypes.string,
}
export default Footer
